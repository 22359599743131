import { createRouter, createWebHistory } from 'vue-router'
// import { watch } from 'vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
// import { user, initialised } from '@/commons/auth/firebase-auth'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    query: {
      zoom: 7,
      lat: 44.77,
      long: -73.76,
      layer: 'Densité de population (2021)'
    },
    meta: { public: true }
  } /* ,
  {
    path: '/share',
    name: 'Share',
    component: Home,
    query: {
      zoom: 7,
      lat: 44.77,
      long: -73.76,
      layer: 'Densité de population (2021)'
    },
    meta: { public: true }
  }, */,
  {
    path: '/protected',
    name: 'Protected',
    component: Home
    // meta: { public: false }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { public: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  // base: process.env.BASE_URL,
  routes
})

/* if (user.value !== false) {
  router.beforeEach((to, _, next) => {
    if (initialised.value) {
      if (!to.matched.some(record => record.meta.public) && !user.value) {
        return next('/login')
      }

      next()
    } else {
      watch(
        () => initialised.value,
        newVal => {
          if (newVal) {
            if (!to.matched.some(record => record.meta.public) && !user.value) {
              return next('/login')
            }

            next()
          }
        }
      )
    }
  })
} */

function createMetaTag(name, content) {
  const tag = document.createElement('meta')
  tag.setAttribute('name', name)
  tag.setAttribute('property', name)
  tag.setAttribute('content', content)
  tag.setAttribute('data-vue-router-controlled', '')
  document.head.appendChild(tag)
}

router.beforeEach((to, from, next) => {
  Array.from(
    document.querySelectorAll('[data-vue-router-controlled]')
  ).map(el => el.parentNode.removeChild(el))

  createMetaTag('og:title', 'Anagraph')
  createMetaTag('og:description', 'Testing Meta Tags')
  createMetaTag('og:image', 'https://www.anagraph.io/anagraph-social.png')
  createMetaTag('og:url', 'https://geometric-data-viewer-dev.web.app/')
  createMetaTag('twitter:card', 'summary_large_image')
  createMetaTag('type', 'website')

  next()
})

export default router
