import disseminationarea2016 from './dissemination_area_2016'
import disseminationarea2021 from './dissemination_area_2021'
import populationAgg2021 from './population_agg_2021'
import canadaLocalpop from './canada_localpop'
import honiMetersEnrichH3R5Symbology from './honi-meters-enrich-h3-r5-symbology.json'
import honiMetersEnrichH3R6Symbology from './honi-meters-enrich-h3-r6-symbology.json'
import honiMetersEnrichH3R7Symbology from './honi-meters-enrich-h3-r7-symbology.json'
import honiMetersEnrichH3R8Symbology from './honi-meters-enrich-h3-r8-symbology.json'
import honiMetersEnrichH3R9Symbology from './honi-meters-enrich-h3-r9-symbology.json'
import intactModelsBuildingsCanadaWidePointSymbology from './intact-models-buildings-canada-wide-point-symbology.json'
import intactModelsBuildingsCanadaWideSymbology from './intact-models-buildings-canada-wide-symbology.json'

export default [
  {
    id: 'croissance-démographique-de-2016-à-2021',
    property: 'variation_population_2021_2016',
    geometryType: 'fill',
    symbologyType: 'ranges',
    symbologyItems: populationAgg2021,
    defaultColor: 'black'
  },
  {
    id: 'densité-de-population-2021',
    property: 'densite_population_2021',
    geometryType: 'fill',
    symbologyType: 'ranges',
    symbologyItems: disseminationarea2021,
    defaultColor: 'black'
  },
  {
    id: 'densité-de-population-2016',
    property: 'r6',
    geometryType: 'fill',
    symbologyType: 'ranges',
    symbologyItems: disseminationarea2016,
    defaultColor: 'black'
  },
  {
    id: 'localpop-2016',
    property: 'population',
    geometryType: 'fill',
    symbologyType: 'ranges',
    symbologyItems: canadaLocalpop,
    defaultColor: 'black'
  },
  {
    id: 'buildings-canada-wide-h3-r5',
    property: 'nb_buildings',
    geometryType: 'fill',
    symbologyType: 'ranges',
    symbologyItems: honiMetersEnrichH3R5Symbology,
    defaultColor: 'black'
  },
  {
    id: 'buildings-canada-wide-h3-r6',
    property: 'nb_buildings',
    geometryType: 'fill',
    symbologyType: 'ranges',
    symbologyItems: honiMetersEnrichH3R6Symbology,
    defaultColor: 'black'
  },
  {
    id: 'buildings-canada-wide-h3-r7',
    property: 'nb_buildings',
    geometryType: 'fill',
    symbologyType: 'ranges',
    symbologyItems: honiMetersEnrichH3R7Symbology,
    defaultColor: 'black'
  },
  {
    id: 'buildings-canada-wide-h3-r8',
    property: 'nb_buildings',
    geometryType: 'fill',
    symbologyType: 'ranges',
    symbologyItems: honiMetersEnrichH3R8Symbology,
    defaultColor: 'black'
  },
  {
    id: 'buildings-canada-wide-h3-r9',
    property: 'nb_buildings',
    geometryType: 'fill',
    symbologyType: 'ranges',
    symbologyItems: honiMetersEnrichH3R9Symbology,
    defaultColor: 'black'
  },
  {
    id: 'buildings-canada-wide',
    property: 'ogc_fid',
    geometryType: 'fill',
    symbologyType: 'ranges',
    symbologyItems: intactModelsBuildingsCanadaWideSymbology,
    defaultColor: 'black'
  },
  {
    id: 'buildings-canada-wide-centroid',
    property: 'ogc_fid',
    geometryType: 'circle',
    symbologyType: 'ranges',
    symbologyItems: intactModelsBuildingsCanadaWidePointSymbology,
    defaultColor: 'black'
  }
]
