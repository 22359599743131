<template>
  <transition name="slide" appear>
    <div v-if="isActive" class="modal modal__share">
      <h1 class="modal__share--title">
        Share This Map
      </h1>
      <!-- <div v-if="false">
        <img :src="canvas" />
      </div> -->
      <div class="modal__share--links">
        <div
          v-for="(link, index) in links"
          :key="index"
          class="modal__share--link"
          @click="share(link)"
        >
          <Icon :class-name="link" :name="link" />
          <span
            v-show="clipBoard && link === 'link'"
            class="modal__share--tooltip"
            >Copied!</span
          >
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref } from 'vue'
import Icon from '@/components/Icon.vue'
import { useRoute } from 'vue-router'
defineProps({
  isActive: {
    type: Boolean
  },
  mapRef: {
    type: String,
    default: null
  }
})
const route = useRoute()
// const canvas = toRef(props, 'mapRef')
const links = ['facebook', 'twitter', 'linkedin', 'email', 'link']

const clipBoard = ref(false)

const share = media => {
  const baseUrl = window.location.origin
  const fullPath = route.fullPath

  if (media === 'link') {
    navigator.clipboard.writeText(`${baseUrl}${fullPath}`)
    clipBoard.value = true
    setTimeout(() => {
      clipBoard.value = false
    }, 1000)
    return
  }

  const u = encodeURIComponent(`${baseUrl}${fullPath}`)
  const t = encodeURIComponent('Geometric Data Viewer')
  const d = encodeURIComponent('Geometric Data Viewer')
  const emailMessage = `Hey!%20%0A%20%20%20%20%20%20%20%20%20%20%20%20%0AI%E2%80%99m%20sharing%20this%20map%20of%20Anagraph%20Geometric%20Data%20Viewer.%0A%0AClick%20on%20the%20following%20link%20to%20open%3A%20`
  const b = `${emailMessage}${u}`

  const networks = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${u}&title=${t}&description=${d}`,
    twitter: `https://twitter.com/intent/tweet?text=${t}&url=${u}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${u}`,
    email: `mailto:?body=${b}`
  }

  window.open(networks[media])
}
</script>

<style lang="scss" scoped>
.slide-enter-active {
  transition: 0.6s;
}
.slide-leave-active {
  transition: 0.2s;
}
</style>
