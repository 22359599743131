import { ref } from 'vue'
import ApiClient from '@/commons/lib/api-client'

const stats = ref(null)

export const fetchStats = async map => {
  const bounds = map.getBounds()
  const client = new ApiClient(
    'https://autopilot.anagraph.io/geometric-data-viewer/run-function'
  )

  const res = await client.post({
    requests: [
      {
        sqlFunction: 'population_2021_vs_2016',
        params: bounds
      }
    ]
  })

  stats.value = res.data[0].result
}

export const getStats = () => {
  const formatStat = stat => Number(stat).toLocaleString('fr-CA')

  const pop2021 = formatStat(stats.value.population_2021)
  const pop2016 = formatStat(stats.value.population_2016)
  const augmentation = formatStat(stats.value.augmentation_population_2016_2021)

  const isStatsLoaded = !!(pop2021 && pop2016 && augmentation)

  return isStatsLoaded
    ? {
        'Population 2021 :': pop2021,
        'Population 2016 :': pop2016,
        'Augmentation 2016 - 2021 :': augmentation
      }
    : null
}
