export default {
  // "firebase": {
  //   "apiKey": "AIzaSyAdgslK7jPL0hVUlJ18k-B3rkus1IbG3zk",
  //   "authDomain": "cookit-map.firebaseapp.com",
  //   "projectId": "cookit-map",
  //   "storageBucket": "cookit-map.appspot.com",
  //   "messagingSenderId": "946381736864",
  //   "appId": "1:946381736864:web:8d58c5ce0d6e1d1225b2c5"
  // }
}
