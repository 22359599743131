<template>
  <div>
    <div class="buttons">
      <Button
        name="stats"
        text="Stats"
        :active-feature="statsPanelState ? 'stats' : null"
        @click="toggleStats"
      />
      <Button
        name="share"
        text="Partager"
        :active-feature="activeModal"
        @click="toggleModal('share')"
      />
      <Button
        name="feedback"
        text="Extraire des données"
        :active-feature="activeModal"
        @click="toggleModal('feedback')"
      />
    </div>
    <Overlay
      :is-active="activeModal !== null && activeModal !== 'stats'"
      @toggle="toggleModal(null)"
    />
    <modal-feedback :is-active="activeModal === 'feedback'" />
    <modal-share :is-active="activeModal === 'share'" :map-ref="map" />
  </div>
</template>

<script setup>
import { computed, ref, toRef } from 'vue'
import { useStore } from 'vuex'
import Overlay from './overlay.vue'
import ModalFeedback from './modal-feedback.vue'
import ModalShare from './modal-share.vue'
import Button from './button.vue'
// import { useRoute, useRouter } from 'vue-router'
const props = defineProps({
  mapRef: {
    type: String,
    default: null
  }
})
const store = useStore()
const map = toRef(props, 'mapRef')
const activeModal = ref(null)
const statsPanelState = computed(() => store.state.statsPanelState)
const searchBoxState = computed(() => store.state.searchBoxState)

const toggleModal = modalName => {
  activeModal.value = modalName === activeModal.value ? null : modalName
}

const toggleStats = () => {
  store.commit('updateStatsPanelState', !statsPanelState.value)
  store.commit('updateSearchBoxState', !searchBoxState.value)
  const leftPanelVisibility = statsPanelState.value ? 'none' : 'block'
  document.querySelector('.left-panel').style.display = leftPanelVisibility
}
</script>

<style lang="scss">
.buttons {
  position: absolute;
  bottom: 3rem;
  right: 3rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;
}

@media only screen and (max-width: 1110px) {
  .buttons {
    bottom: 1.6rem;
    right: 1.6rem;
  }
}

@media only screen and (max-width: 775px) {
  .buttons {
    bottom: 1.4rem;
    right: 1.4rem;
  }
}
@media only screen and (max-width: 425px) {
  .buttons {
    bottom: 1.2rem;
    right: 1.2rem;
  }
}
</style>
