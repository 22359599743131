import axios from 'axios'
export default class ApiClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  async get(endpoint, params) {
    const requestParams = { ...params }
    try {
      const response = await axios.get(this.baseUrl + endpoint, {
        params: requestParams
      })
      return response.data
    } catch (err) {
      console.log('err in backend client get method ===>', err.response.data)
      alert('something went wrong. Please try again later !')
      throw err
    }
  }

  async post(body) {
    try {
      const response = await axios.post(this.baseUrl, body)
      return response.data
    } catch (err) {
      console.log('err in backend client post method ===>', err.response.data)
      alert('something went wrong. Please try again later !')
      throw err
    }
  }
}
