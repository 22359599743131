import mapboxgl from 'mapbox-gl'
import { ref } from 'vue'
import popupConfig from './popupConfig.json'
import axios from 'axios'

const popupRefs = popupConfig.map(() => {
  const popupRef = ref(null)
  return popupRef
})

const fetchAddress = async (long, lat) => {
  const baseUrl = 'https://revgeocode.search.hereapi.com/v1/revgeocode'
  const lang = 'fr-fr'
  const apiKey = process.env.VUE_APP_HERE_API_KEY

  try {
    const address = await axios(
      `${baseUrl}?at=${lat}%2C${long}&lang=${lang}&apiKey=${apiKey}`
    )
      .then(res => res.data.items[0])
      .then(items => items.address)

    return address
  } catch (err) {
    console.log(err.message)
  }
}

const getCoordinates = e => {
  const long = e.lngLat.lng
  const lat = e.lngLat.lat
  return { long, lat }
}

export const usePopup = map => {
  popupConfig.forEach((layer, i) => {
    map.on('mouseenter', layer.layerName, () => {
      map.getCanvas().style.cursor = 'pointer'
    })
    map.on('mouseleave', layer.layerName, () => {
      map.getCanvas().style.cursor = ''
    })
    map.on('click', layer.layerName, async e => {
      const feature = e.features[0]
      const featureProps = feature.properties
      const { long, lat } = getCoordinates(e)
      let htmlContent = ''

      const address = await fetchAddress(long, lat)
      const city = address?.city

      htmlContent += `<p><b>Ville:</b> ${city}</p>`

      layer.layerProps.forEach(layerProp => {
        const value =
          layerProp.prop === 'population'
            ? Math.round(featureProps[layerProp.prop])
            : featureProps[layerProp.prop]
        htmlContent += `<p><b>${layerProp.label}:</b> ${value} ${
          layerProp.unit === '' ? '' : layerProp.unit
        }</p>`
      })
      popupRefs[i].value = new mapboxgl.Popup()
        .setLngLat(e.lngLat)
        .setHTML(htmlContent)
        .addTo(map)
      return popupRefs[i].value
    })
  })
}

export const getPopup = () => {
  return popupRefs
}

export const removePopup = () => {
  getPopup().forEach(popupRef => {
    if (popupRef.value) popupRef.value.remove()
  })
}
