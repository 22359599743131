import { onMounted, watch } from 'vue'

export const useToggleLayers = (
  mapRef,
  layersStyledConfig,
  selectedLayerGroup
) => {
  onMounted(() => {
    const map = mapRef.value.map
    map.on('load', () => {
      layersStyledConfig.forEach(layerGroup => {
        const isLayerGroupSelected =
          layerGroup.id === selectedLayerGroup.value.id

        layerGroup.layers.forEach(({ id, source, style, before }) => {
          const sourceId = `${id}-source`
          const layerId = id
          map.addSource(sourceId, source)
          map.addLayer(
            {
              ...style,
              id: layerId,
              source: sourceId,
              layout: {
                ...style.layout,
                visibility: isLayerGroupSelected ? 'visible' : 'none'
              }
            },
            before
          )
        })
      })
    })
  })
  watch(selectedLayerGroup, (newVal, oldVal) => {
    const layerGroupToShow = newVal
    const layerGroupToHide = oldVal
    if (layerGroupToShow) {
      layerGroupToShow.layers.forEach(layer => {
        mapRef.value.map.setLayoutProperty(layer.id, 'visibility', 'visible')
      })
    }
    if (layerGroupToHide) {
      layerGroupToHide.layers.forEach(layer => {
        mapRef.value.map.setLayoutProperty(layer.id, 'visibility', 'none')
      })
    }
  })
  return {
    mapRef
  }
}
