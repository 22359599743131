<template>
  <div class="search">
    <div class="search__box">
      <Icon
        class-name="search__icon search__icon--search"
        name="search"
        size="40"
        @click="searchHandler(suggestions?.[0])"
      />
      <span class="search__separator"></span>
      <input
        v-model="searchQuery"
        type="text"
        class="search__input"
        placeholder="Chercher..."
        @input="suggestionsHandler"
        @click="isSuggestionsActive = true"
        @blur="focusOutHandler"
        @keydown.enter="searchHandler(suggestions?.[0])"
      />
      <span class="search__separator"></span>
      <Icon
        class-name="search__icon search__icon--clear"
        name="clear"
        size="40"
        @click="clearHandler"
      />
    </div>
    <div v-if="suggestions && isSuggestionsActive" class="search__suggestions">
      <div
        v-for="suggestion in suggestions"
        :key="suggestion.id"
        class="search__suggestion"
        @click="searchHandler(suggestion)"
      >
        {{ suggestion.address.label }} - {{ suggestion.address.city }} -
        {{ suggestion.address.stateCode }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, toRef, watch } from 'vue'
import mapboxGl from 'mapbox-gl'
import * as _ from 'lodash'
import axios from 'axios'
import Icon from '@/components/Icon.vue'
import { useStore } from 'vuex'
const props = defineProps({
  mapRef: {
    type: Object,
    default: null
  }
})
const store = useStore()
const map = toRef(props, 'mapRef')
const searchQuery = ref('')
const suggestions = ref([])
const isSuggestionsActive = ref(true)
const marker = new mapboxGl.Marker({ color: '#20202c' })
const searchAnimationMode = computed(() => store.state.searchAnimationMode)

const searchHandler = suggestion => {
  if (!suggestion) return
  isSuggestionsActive.value = false
  marker.remove()
  searchQuery.value = suggestion.title

  if (!searchAnimationMode.value) {
    const zoomLevel = {
      place: 14,
      houseNumber: 14,
      street: 14,
      intersection: 14,
      postalCodePoint: 14,
      locality: 10,
      administrativeArea: 5
    }
    map.value.jumpTo({
      center: [suggestion.position.lng, suggestion.position.lat],
      zoom: zoomLevel[suggestion.resultType]
    })
    marker.setLngLat(suggestion.position).addTo(map.value)
    return
  }

  const flytoTypes = [
    'place',
    'houseNumber',
    'street',
    'intersection',
    'postalCodePoint'
  ]
  const fitBoundsTypes = ['locality', 'administrativeArea']

  if (
    flytoTypes.includes(suggestion.resultType) ||
    suggestion.title === 'Canada'
  ) {
    map.value.flyTo({
      center: [suggestion.position.lng, suggestion.position.lat],
      essential: true,
      zoom: suggestion.title === 'Canada' ? 3.7 : 17
    })
    marker.setLngLat(suggestion.position).addTo(map.value)
  }
  if (
    fitBoundsTypes.includes(suggestion.resultType) &&
    suggestion.title !== 'Canada'
  ) {
    map.value.fitBounds([
      [suggestion.mapView.west, suggestion.mapView.south],
      [suggestion.mapView.east, suggestion.mapView.north]
    ])
    marker.setLngLat(suggestion.position).addTo(map.value)
  }
}

const suggestionsHandler = _.debounce(async () => {
  const apiKey = `apiKey=${process.env.VUE_APP_HERE_API_KEY}`
  const baseUrl = 'https://discover.search.hereapi.com/v1/discover'

  if (!searchQuery.value || searchQuery.value.length < 4) {
    suggestions.value = []
    marker.remove()
    return
  }

  const query = `q=${searchQuery.value}`
  const { lng, lat } = map.value.getCenter()
  const at = `at=${lat},${lng}`
  const limit = 'limit=10'
  const countryCode = 'in=countryCode:CAN'

  const res = await axios(
    `${baseUrl}?${query}&${at}&${limit}&${countryCode}&${apiKey}`
  )

  suggestions.value = res.data.items
}, 300)

const focusOutHandler = () => {
  setTimeout(() => {
    isSuggestionsActive.value = false
  }, 200)
}

const clearHandler = () => {
  searchQuery.value = ''
  suggestions.value = []
  marker.remove()
}

watch(searchQuery, async () => {
  await suggestionsHandler()
})
</script>
