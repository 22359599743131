export default [
  {
    id: 'Croissance démographique de 2016 à 2021',
    layers: [
      {
        id: 'croissance-démographique-de-2016-à-2021',
        source: {
          type: 'vector',
          tiles: [
            'https://warehouse.anagraph.io/statcan_input.aire_diffusion_population_aggrege_2021_3857/{z}/{x}/{y}.pbf'
          ]
        },
        style: {
          type: 'fill',
          'source-layer':
            'statcan_input.aire_diffusion_population_aggrege_2021_3857',
          paint: {
            'fill-opacity': 0.7
          }
        },
        before: 'road_minor_case'
      }
    ]
  },
  {
    id: 'Densité de population (2021)',
    layers: [
      {
        id: 'densité-de-population-2021',
        source: {
          type: 'vector',
          tiles: [
            'https://warehouse.anagraph.io/statcan_input.aire_diffusion_population_2021/{z}/{x}/{y}.pbf'
          ]
        },
        style: {
          type: 'fill',
          'source-layer': 'statcan_input.aire_diffusion_population_2021',
          paint: {
            'fill-opacity': 0.7
          }
        },
        before: 'road_minor_case'
      }
    ]
  },
  {
    id: 'Densité de population (2016)',
    layers: [
      {
        id: 'densité-de-population-2016',
        source: {
          type: 'vector',
          tiles: [
            'https://warehouse.anagraph.io/statcan_input.demo2016_ad/{z}/{x}/{y}.pbf'
          ]
        },
        style: {
          type: 'fill',
          'source-layer': 'statcan_input.demo2016_ad',
          paint: {
            'fill-opacity': 0.7
          }
        },
        before: 'road_minor_case'
      }
    ]
  },
  {
    id: 'LocalPop 2016',
    layers: [
      {
        id: 'localpop-2016',
        source: {
          type: 'vector',
          tiles: [
            'https://warehouse.anagraph.io/statcan_input.canada_localpop_gteq_100/{z}/{x}/{y}.pbf'
          ]
        },
        style: {
          type: 'fill',
          'source-layer': 'statcan_input.canada_localpop_gteq_100',
          paint: {
            'fill-opacity': 0.7
          }
        },
        before: 'road_minor_case'
      }
    ]
  },
  {
    id: 'Building footprints',
    layers: [
      {
        id: 'buildings-canada-wide-h3-r5',
        source: {
          type: 'vector',
          tiles: [
            'https://warehouse.anagraph.io/public.buildings_canada_wide_h3_r5/{z}/{x}/{y}.pbf'
          ]
        },
        style: {
          type: 'fill',
          'source-layer': 'public.buildings_canada_wide_h3_r5',
          paint: {
            'fill-opacity': 0.8
          },
          minzoom: 1,
          maxzoom: 4
        },
        before: 'road_minor_case'
      },
      {
        id: 'buildings-canada-wide-h3-r6',
        source: {
          type: 'vector',
          tiles: [
            'https://warehouse.anagraph.io/public.buildings_canada_wide_h3_r6/{z}/{x}/{y}.pbf'
          ]
        },
        style: {
          type: 'fill',
          'source-layer': 'public.buildings_canada_wide_h3_r6',
          paint: {
            'fill-opacity': 0.8
          },
          minzoom: 4,
          maxzoom: 7
        },
        before: 'road_minor_case'
      },
      {
        id: 'buildings-canada-wide-h3-r7',
        source: {
          type: 'vector',
          tiles: [
            'https://warehouse.anagraph.io/public.buildings_canada_wide_h3_r7/{z}/{x}/{y}.pbf'
          ]
        },
        style: {
          type: 'fill',
          'source-layer': 'public.buildings_canada_wide_h3_r7',
          paint: {
            'fill-opacity': 0.8
          },
          minzoom: 7,
          maxzoom: 9.5
        },
        before: 'road_minor_case'
      },
      {
        id: 'buildings-canada-wide-h3-r8',
        source: {
          type: 'vector',
          tiles: [
            'https://warehouse.anagraph.io/public.buildings_canada_wide_h3_r8/{z}/{x}/{y}.pbf'
          ]
        },
        style: {
          type: 'fill',
          'source-layer': 'public.buildings_canada_wide_h3_r8',
          paint: {
            'fill-opacity': 0.8
          },
          minzoom: 9.5,
          maxzoom: 12.5
        },
        before: 'road_minor_case'
      },
      {
        id: 'buildings-canada-wide-h3-r9',
        source: {
          type: 'vector',
          tiles: [
            'https://warehouse.anagraph.io/public.buildings_canada_wide_h3_r9/{z}/{x}/{y}.pbf'
          ]
        },
        style: {
          type: 'fill',
          'source-layer': 'public.buildings_canada_wide_h3_r9',
          paint: {
            'fill-opacity': 0.8
          },
          minzoom: 12.5,
          maxzoom: 14.5
        },
        before: 'road_minor_case'
      },
      {
        id: 'buildings-canada-wide-centroid',
        source: {
          type: 'vector',
          tiles: [
            'https://warehouse.anagraph.io/public.buildings_canada_wide_point/{z}/{x}/{y}.pbf'
          ]
        },
        style: {
          type: 'circle',
          'source-layer': 'public.buildings_canada_wide_point',
          paint: {
            'circle-color': '#c59457',
            'circle-opacity': 0.7,
            'circle-radius': ['interpolate', ['linear'], ['zoom'], 12, 2, 14, 3]
          },
          minzoom: 14.5,
          maxzoom: 16
        },
        after: 'road_minor_case'
      },
      {
        id: 'buildings-canada-wide',
        source: {
          type: 'vector',
          tiles: [
            'https://warehouse.anagraph.io/public.buildings_canada_wide/{z}/{x}/{y}.pbf'
          ]
        },
        style: {
          type: 'fill',
          'source-layer': 'public.buildings_canada_wide',
          paint: {
            'fill-color': '#c59457',
            'fill-opacity': 0.6
          },
          minzoom: 16,
          maxzoom: 24
        },
        after: 'road_minor_case'
      }
    ]
  }
]
