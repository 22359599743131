<template>
  <div class="home">
    <left-panel-layout class="app-layout">
      <template #left-panel>
        <div v-if="mapLoaded" class="left-panel__container">
          <h2 class="left-panel__title">Geometric Data</h2>
          <RadioSelector
            v-model:selected="selectedLayerGroup"
            @load-items="isItemsLoading = true"
          />
          <span v-if="categoryWidgetsUnit" class="left-panel__stats-unit">
            {{ categoryWidgetsUnit }}
          </span>
          <div v-if="isItemsLoading" class="status_message">
            <p>Loading ...</p>
          </div>
          <div v-else>
            <div
              v-for="widget in categoryWidgets"
              :key="categoryWidgets.indexOf(widget)"
            >
              <category-widget
                v-show="isWidgetActive(widget)"
                :ref="setCategoryWidgets"
                :items="widget.items"
                filter-type="simple-filter"
                @filter="applyFilter"
              />
            </div>
          </div>
        </div>
        <div v-else>loading ...</div>
      </template>
      <template #map>
        <Map
          :token="token"
          :layers-config="layersConfig"
          :selected-layer-group="selectedLayerGroup"
          :filter="mapFilter"
          @loaded="mapLoaded = true"
          @features-updated="featuresUpdated"
          @stats-update="statsUpdate"
          @map-updated="mapUpdated"
        />
      </template>
    </left-panel-layout>
    <panel v-if="stats && statsPanelState" class="right-panel">
      <Stats :stats="stats" />
    </panel>
    <Buttons />
    <SearchBox v-show="searchBoxState" :map-ref="mapRef" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import LeftPanelLayout from '@anagraph/left-panel-layout'
import Panel from '@anagraph/panel'
import CategoryWidget from '@anagraph/category-widget'
import RadioSelector from '@/components/radio-selector'
import Map from '@/components/map'
import SearchBox from '@/components/search-box.vue'
import Stats from '@/components/stats.vue'
import Buttons from '@/components/buttons'
import { getToken } from '@/commons/auth/firebase-auth'
import { getStats } from '@/commons/lib/statsHelper'
import layersConfigurations from '@/layer-configurations'
import layerSymbologies from '@/layer-symbologies'
import {
  addLayersSymbologies,
  getCategoryWidgetItems,
  createLayerFilter
} from '@/symbologiesHelper.js'

const store = useStore()
const route = useRoute()

const layersConfig = addLayersSymbologies(
  layersConfigurations,
  layerSymbologies
)
const mapLoaded = ref(false)
const token = ref(null)
const mapFilter = ref([])

const routeLayerGroupId = route.query.layer?.split('-').join(' ')
const defaultSelectedLayerGroup = routeLayerGroupId
  ? layersConfigurations.find(layerGroup => layerGroup.id === routeLayerGroupId)
  : layersConfigurations[0]

const mapRef = ref(null)
const selectedLayerGroup = ref(defaultSelectedLayerGroup)
const activeLayerId = ref(null)
const widgetsRef = ref([])
const categoryWidgets = ref([])
const isItemsLoading = ref(true)
const stats = ref(null)

const categoryWidgetsUnit = computed(() => {
  if (selectedLayerGroup.value.id === 'Croissance démographique de 2016 à 2021')
    return null
  else if (selectedLayerGroup.value.id === 'LocalPop 2016')
    return "( Nombre d'habitants )"
  else return '( pop / km\xB2 )'
})

const mapUpdated = map => {
  mapRef.value = map
}

const isScreenSmall = computed(() => store.state.isScreenSmall)
const statsPanelState = computed(() => store.state.statsPanelState)
const searchBoxState = computed(() => store.state.searchBoxState)

onMounted(async () => {
  token.value = await getToken()

  watchEffect(() => {
    if (isScreenSmall.value) {
      store.commit('updateStatsPanelState', false)
      store.commit('updateSearchBoxState', true)
    } else {
      store.commit('updateStatsPanelState', true)
      store.commit('updateSearchBoxState', true)
      document.querySelector('.left-panel').style.display = 'block'
    }
  })
})

const featuresUpdated = features => {
  categoryWidgets.value = getCategoryWidgetItems(
    selectedLayerGroup.value,
    layerSymbologies,
    features
  )
  isItemsLoading.value = false
}

const applyFilter = filter => {
  mapFilter.value = createLayerFilter(
    filter,
    activeLayerId.value,
    layerSymbologies
  )
}

const isWidgetActive = widget => {
  if (categoryWidgets.value.length > 1) {
    const layer = layersConfigurations
      .find(layerGroup => widget.layerGroup === layerGroup.id)
      .layers.find(layer => layer.id === widget.id)

    const isLayerVisible =
      layer.style.minzoom < mapRef.value.getZoom() &&
      layer.style.maxzoom > mapRef.value.getZoom()

    if (isLayerVisible) activeLayerId.value = layer.id

    return isLayerVisible
  } else {
    activeLayerId.value = widget.id
    return true
  }
}

const setCategoryWidgets = categoryWidget => {
  widgetsRef.value.push(categoryWidget)
}

const statsUpdate = () => {
  stats.value = getStats()
}

watch(selectedLayerGroup, () => {
  if (widgetsRef.value) {
    widgetsRef.value.forEach(widget => {
      if (widget?.$refs?.filters) widget.$refs.filters.reset()
    })
  }
})
</script>
