import { createStore } from 'vuex'

export default createStore({
  state: {
    isScreenSmall: window.innerWidth <= 685,
    searchAnimationMode: window.innerWidth > 1024,
    statsPanelState: true,
    searchBoxState: true
  },
  mutations: {
    updateIsScreenSmall(state, payload) {
      state.isScreenSmall = payload
    },
    updateSearchAnimationMode(state) {
      state.searchAnimationMode = !state.searchAnimationMode
    },
    updateStatsPanelState(state, payload) {
      state.statsPanelState = payload
    },
    updateSearchBoxState(state, payload) {
      state.searchBoxState = payload
    }
  },
  actions: {},
  modules: {}
})
