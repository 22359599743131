import { useRoute, useRouter } from 'vue-router'

let route
let router
let defaultCenter
let defaultZoom

// Sets starting map position according to url
export const setMapPosition = () => {
  route = useRoute()
  router = useRouter()
  const urlLat = route.query.lat
  const urlLong = route.query.long
  defaultCenter =
    urlLat && urlLong
      ? { lng: urlLong, lat: urlLat }
      : { lng: urlLong || -73.76, lat: urlLat || 44.77 }

  defaultZoom = route.query.zoom || 5
}

// Returns the values of map position to be passed to map component
export const getMapPosition = () => {
  return { zoom: defaultZoom, center: defaultCenter }
}

// Updates url when we move in map
export const updateMapPosition = (map, selectedLayerGroup) => {
  const mapZoom = map.getZoom().toFixed(2)
  const mapLat = map.getCenter().lat.toFixed(4)
  const mapLong = map.getCenter().lng.toFixed(4)
  router.replace({
    path: route.path,
    query: {
      zoom: mapZoom,
      lat: mapLat,
      long: mapLong,
      layer: selectedLayerGroup.id.split(' ').join('-')
    }
  })
}
