<template>
  <div class="radios-selector">
    <div
      v-for="layerGroup in layersConfigurations"
      :key="layersConfigurations.indexOf(layerGroup)"
      class="radios-selector__item"
    >
      <input
        :id="layerGroup.id"
        v-model="selectedLayerGroup"
        type="radio"
        :value="layerGroup"
      />
      <label :for="layerGroup.id">{{ layerGroup.id }}</label>
    </div>
  </div>
</template>

<script setup>
import { watch, ref } from 'vue'
import layersConfigurations from '@/layer-configurations'

const props = defineProps({
  selected: {
    type: Object,
    default: null
  }
})

const emit = defineEmits(['update:selected', 'load-items'])

const selectedLayerGroup = ref(props.selected || 'one')

watch(selectedLayerGroup, val => {
  emit('update:selected', val)
  emit('load-items')
})
</script>
