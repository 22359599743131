<template>
  <div>
    <gl-map
      ref="mapRef"
      :init-map-position="options"
      :logo-control="logoControl"
    />
  </div>
</template>

<script setup>
import { shallowRef, onMounted, toRef, watch } from 'vue'
import GlMap from '@anagraph/gl-map'
import { useToggleLayers } from '../commons/lib/useToggleLayers'
import { usePopup, removePopup } from '../commons/lib/popupHelper'
import { LogoControl } from '@/components/map-options/map-control'
import { fetchStats } from '@/commons/lib/statsHelper'
import {
  setMapPosition,
  getMapPosition,
  updateMapPosition
} from '@/components/map-options/mapPosition'
const props = defineProps({
  token: {
    type: String,
    default: null
  },
  layersConfig: {
    type: Array,
    default: () => []
  },
  selectedLayerGroup: {
    type: Object,
    default: null
  },
  filter: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits([
  'loaded',
  'features-updated',
  'stats-update',
  'map-updated'
])
const mapRef = shallowRef(null)
const filter = toRef(props, 'filter')
const selectedLayerGroup = toRef(props, 'selectedLayerGroup')
const logoControl = LogoControl
useToggleLayers(mapRef, props.layersConfig, selectedLayerGroup)
setMapPosition()
onMounted(() => {
  const map = mapRef.value.map
  usePopup(map)
  emit('loaded', true)
  map.on('idle', async () => {
    updateMapPosition(map, selectedLayerGroup.value)
    const selectedLayers = selectedLayerGroup.value.layers.map(
      layer => layer.id
    )
    const features = map.queryRenderedFeatures({
      layers: selectedLayers
    })
    emit('features-updated', features)

    emit('map-updated', map)
    await fetchStats(map)
    emit('stats-update')
  })
})
watch(filter, newVal => {
  if (!newVal) return
  newVal.map(({ layerId, filter: mapboxFilter }) => {
    mapRef.value.map.setFilter(layerId, mapboxFilter)
  })
})
watch(selectedLayerGroup, () => {
  removePopup()
})
const transformRequest = (url, resourceType) => {
  if (!process.env.VUE_APP_PROTECTED_TILES_URL) return
  if (
    resourceType === 'Tile' &&
    url.startsWith(process.env.VUE_APP_PROTECTED_TILES_URL)
  ) {
    return {
      url: url,
      headers: { Authorization: `Bearer ${props.token}` }
    }
  }
}
const options = { ...getMapPosition(), transformRequest, minZoom: 3.5 }
</script>
