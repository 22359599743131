<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const isScreenSmall = computed(() => store.state.isScreenSmall)
const searchAnimationMode = computed(() => store.state.searchAnimationMode)
window.addEventListener('resize', () => {
  if (isScreenSmall.value !== window.innerWidth <= 685) {
    store.commit('updateIsScreenSmall', !isScreenSmall.value)
  }
  if (searchAnimationMode.value !== window.innerWidth > 1024) {
    store.commit('updateSearchAnimationMode')
  }
})
</script>
